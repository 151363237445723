<template>
  <NuxtLink
    :to="link"
    class="border-orange-50 border-2 p-5 md:p-8 rounded-3xl text-center bg-no-repeat bg-cover bg-center service-card__item"
  >
    <figure
      class="w-14 h-14 md:w-40 md:h-40 mx-auto flex justify-center items-center rounded-full mb-4"
    >
      <img :src="serviceIconImg(idx + 1)" class="max-w-full" alt="" />
    </figure>
    <h4 class="text-base md:text-2xl md:mb-7">
      {{ service.service.data.attributes.title }}
    </h4>
  </NuxtLink>
</template>
<script>
import icon_1 from "@/assets/img/index/services/icon/icon_1.svg";
import icon_2 from "@/assets/img/index/services/icon/icon_2.svg";
import icon_3 from "@/assets/img/index/services/icon/icon_3.svg";

export default {
  data() {
    return { icon_1: icon_1, icon_2: icon_2, icon_3: icon_3 };
  },
  props: ["service", "idx"],
  computed: {
    link() {
      if (this.service?.service?.data?.attributes?.absoluteLink) {
        return this.service.service.data.attributes.absoluteLink;
      }
      if (this.service?.service?.data?.attributes?.link) {
        return `/services/${this.service.service.data.attributes.link}`;
      }
      return "";
    },
  },
  methods: {
    serviceIconImg(idx) {
      switch (idx) {
        case 1:
          return this.icon_1;
          break;
        case 2:
          return this.icon_2;
          break;
        case 3:
          return this.icon_3;
          break;
      }
    },
  },
  mounted() {
    // console.log(this.service, this.idx);
  },
};
</script>

<style lang="scss">
.service-card__item {
  &:nth-child(1) {
    background-image: url("@/assets/img/index/services/bg/service-bg_1.webp");
  }

  &:nth-child(2) {
    background-image: url("@/assets/img/index/services/bg/service-bg_2.webp");
  }

  &:nth-child(3) {
    background-image: url("@/assets/img/index/services/bg/service-bg_3.webp");
  }

  @media screen and (max-width: 991px) {
    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
</style>
